


































































import Vue from 'vue';
import Component from 'vue-class-component';
import CommonFormElement from 'common-modules/src/components/CommonFormElement.vue';
import BackBar from '@/components/BackBar.vue';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';
import { User } from '@/store/interfaces/User';

interface SecutixData {
  individualFirstname: string;
  individualLastname: string;
  individualGender: string;
  email: string;
  contactNumber: string;
}

@Component({
  components: {
    JwlSubmitRow,
    BackBar,
    CommonFormElement,
  },
})
export default class SisUserImport extends Vue {
  $refs!: {
    importForm: HTMLFormElement;
  }

  importData: SecutixData|null = null;
  existingData: User|null = null;
  submitStatus = 0;
  error = null;

  submitForm (): void {
    const { importForm } = this.$refs;

    if (importForm.reportValidity() && this.submitStatus !== 1) {
      this.submitStatus = 1;
      const formData = new FormData(importForm);

      if (this.importData) {
        formData.set('email', this.importData.email);
      }

      this.$store.dispatch('postData', {
        url: 'user/import',
        formData,
      }).then((data) => {
        this.importData = null;
        this.existingData = null;
        if (data.user) {
          this.existingData = data.user;
        }
        if (data.secutix) {
          this.importData = data.secutix;
        }

        this.submitStatus = 2;
        setTimeout(() => {
          this.submitStatus = 0;
        }, 2500);
      }).catch((e) => {
        this.error = e;
        this.submitStatus = -1;
      });
    } else {
      this.submitStatus = 3;
    }
  }
}
